import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const instaFeed = [
  {
    id: 1,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img1-home.png"
        alt="slider"
      />
    ),
    alt: "img1",
  },
  {
    id: 2,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img2-home.png"
        alt="slider"
      />
    ),
    alt: "img2",
  },
  {
    id: 3,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img3-home.png"
        alt="slider"
      />
    ),
    alt: "img3",
  },
  {
    id: 4,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img4-home.png"
        alt="slider"
      />
    ),
    alt: "img4",
  },
  {
    id: 5,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img5-home.png"
        alt="slider"
      />
    ),
    alt: "img5",
  },
  {
    id: 6,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img6-home.png"
        alt="slider"
      />
    ),
    alt: "img6",
  },
  {
    id: 7,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img7-home.png"
        alt="slider"
      />
    ),
    alt: "img7",
  },
  {
    id: 8,
    img: (
      <StaticImage
        placeholder="blurred"
        src="../../../static/insta/img8-home.png"
        alt="slider"
      />
    ),
    alt: "img8",
  },
];

export default instaFeed;
